<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-row :gutter="20" v-if="!$route.params.id">
                <el-col :sm="24" :md="8" :lg="8">
                    <el-form-item label="Search Patient" prop="patient_id">
                        <el-select
                            v-model="patient_id"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="Please enter phone number"
                            :remote-method="searchGlobalPatient"
                            clearable
                            class="w-100"
                            required
                        >
                        <el-option
                            v-for="item in patients"
                            :key="item.id"
                            :label="item.fullname"
                            :value="item.id"
                        />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="4" :lg="4">
                    <el-button type="success" round @click="patientDetails()">Search</el-button>
                </el-col>
            </el-row>

        
            <el-form
                :model="form"
                label-position="top"
                label-width="100px"
                ref="installment"
                v-if="Object.keys(patient_details).length !== 0">

                <div class="text-center">
                    <h3>অর্ক হেলথ লিমিটেড </h3>
                    <p class="mb-0 mt-0">সাধারণ অনুদানের যোগ্য রোগী নির্বাচনী ফরম</p>
                </div>

                <h4> <b>Basic Information : </b></h4>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" রোগীর নামঃ  ">
                            <el-input v-model="form.fullname" disabled/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" মোবাইল নাম্বারঃ  ">
                            <el-input v-model="form.phone_number" disabled/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" এন আইডি নাম্বারঃ ">
                            <el-input v-model="form.identity_card_no"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" জন্ম তারিখঃ ">
                            <el-input v-model="form.dob" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" বয়সঃ ">
                            <el-input v-model="form.age" disabled/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" পিতার নামঃ ">
                            <el-input v-model="form.father_name"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" মায়ের নামঃ ">
                            <el-input v-model="form.mother_name"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" পিতার/পেশাঃ  ">
                            <el-input v-model="form.father_profession"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" জেলাঃ   ">
                            <el-select v-model="form.region_id" filterable clearable class="m-2 w-100" @change="form.town_id = '';getThana()">
                                <el-option
                                v-for="item in districts"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="থানাঃ ">
                            
                            <el-select v-model="form.town_id" filterable clearable class="m-2 w-100">
                                <el-option
                                v-for="item in thana"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                />
                            </el-select>
                        
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" ইউনিয়ন/ওয়ার্ডঃ ">
                            <el-input v-model="form.ward"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" গ্রামঃ ">
                            <el-input v-model="form.village"/>
                        </el-form-item>
                    </el-col>
                </el-row>


                <h4> <b>Other Information : </b></h4>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি কি পূর্বে কখনো অনুদান গ্রহণ করেছিলেন? গ্রহণ করলে কতবার করেছেন? ">
                            <el-radio-group v-model="form.prev_welfare_taken">
                            <el-radio :label="1">হ্যা</el-radio>
                            <el-radio :label="0">না</el-radio>
                            </el-radio-group>
                            <el-input v-if="form.prev_welfare_taken==1" v-model="prev_welfare_taken" placeholder="কতবার"
                          type="number"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনার কি স্বল্পমূল্যে প্রয়োজনীয় দ্রবাদি কিনার জন্য সরকার কর্তৃক প্রদানকৃত ভি.জি.এফ. কার্ড আছে?">
                            <el-radio-group v-model="form.has_vgf_card">
                            <el-radio :label="1">আছে</el-radio>
                            <el-radio :label="0">নাই</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি প্রাকৃতিক দূ্র্যোগ, ঘূর্ণিঝড়, জলোচ্ছ্বাস, বন্যা, নদীভাঙন ইত্যাদিতে আক্রান্ত? কোনটিতে আক্রান্ত">
                            <el-radio-group v-model="form.natural_disaster_victim">
                            <el-radio :label="1">হ্যা</el-radio>
                            <el-radio :label="0">না</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনার পরিবারের লোকসংখ্যা কতজন?">
                            <el-radio-group v-model="form.no_of_family_member">
                            <el-radio :label="1">চারজন থেকে বেশি</el-radio>
                            <el-radio :label="0">একজন-চারজন</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="উপার্জনক্ষম লোকের সংখ্যা কতজন? ">
                            <el-radio-group v-model="form.no_of_income_member">
                            <el-radio :label="0">একজন</el-radio>
                            <el-radio :label="1">দুইজন/দুইজন থেকে বেশি</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="অধ্যয়নরত সদস্য সংখ্যা কতজন? ">
                            <el-radio-group v-model="form.no_of_study_member">
                            <el-radio :label="1">দুইজন থেকে বেশি</el-radio>
                            <el-radio :label="0">দুইজন/একজন</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="পারিবারিক মাসিক মোট ব্যয়  ">
                            <el-radio-group v-model="form.monthly_family_expense">
                            <el-radio :label="1">আয় থেকে ব্যয় বেশি</el-radio>
                            <el-radio :label="0">আয় ব্যয় সমান/আয় থেকে ব্যয় কম</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি কি ঋণগ্রস্থ (টাকার পরিমাণ)  ">
                            <el-radio-group v-model="form.loan_amount">
                            <el-radio :label="1">হ্যা</el-radio>
                            <el-radio :label="0">না</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি কি ঋণগ্রস্থ (টাকার পরিমাণ)  ">
                            <el-radio-group v-model="form.your_monthly_expense">
                            <el-radio label="1">হ্যা</el-radio>
                            <el-radio label="0">না</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col> -->
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি কি সুদসহ কিস্তি প্রদান করেন?">
                            <el-radio-group v-model="form.has_installment_with_interest">
                            <el-radio :label="1">হ্যা</el-radio>
                            <el-radio :label="0">না</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনার মাসিক মোট ব্যয় কত? ">
                            <el-radio-group v-model="form.your_monthly_expense">
                            <el-radio :label="0">৭০০০ টাকা</el-radio>
                            <el-radio :label="1">৭০০০-১২০০০ টাকা</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনার কি নিজস্ব জায়গা জমি আছে? ">
                            <el-radio-group v-model="form.has_own_land">
                            <el-radio :label="0">না</el-radio>
                            <el-radio :label="1">হ্যা</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনার কি নিজস্ব বাসা/বাড়ি আছে?">
                            <el-radio-group v-model="form.has_own_house">
                            <el-radio :label="0">না</el-radio>
                            <el-radio :label="1">হ্যা</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="আপনি কি ভাড়া বাসায় থাকেন?">
                            <el-radio-group v-model="form.has_house_rent">
                            <el-radio :label="0">না</el-radio>
                            <el-radio :label="1">হ্যা</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="8" :lg="8">
                            <el-form-item
                                label="Select Package"
                                prop="orko_therapy_package_id"
                                >
                                <el-select
                                    @change="clickPackage"
                                    class="w-100"
                                    clearable
                                    filterable
                                    @clear="clearPackageSelection"
                                    v-model="form.orko_therapy_package_id"
                                    placeholder="Select Service"
                                >
                                <el-option
                                    v-for="item in packages"
                                    :key="item.id"
                                    :label="item.label?'Package No - ' + item.package_no + ' ('+item.label+')':'Package No - ' + item.package_no"
                                    :value="item.id"
                                >
                                <span>Package No - {{ item.package_no }} {{ item.label?' ('+item.label+')':''}}</span>
                                </el-option>
                        </el-select>
                    </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" সার্ভিসের মোট মূল্য  ">
                            <el-input v-model="form.total_service_amount" type="number"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" রোগী কত টাকা দিতে পারবে ?  ">
                            <el-input v-model="form.patient_amount" type="number" @input="calculateExpectedDiscount"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" রোগী কত টাকা অনুদান চাচ্ছে ?  ">
                            <el-input v-model="form.expected_discount_amount" type="number" @input="calculateExpectedDiscount"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Recommended By">
                            <el-input v-model="form.recommended_by" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Approved By">
                            <el-input v-model="form.approved_by" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16" :lg="16">
                        <el-form-item label=" কর্তব্যরত ডাক্তার মন্তব্য">
                            <el-input v-model="form.duty_doctor_comment" type="textarea" />
                        </el-form-item>
                    </el-col>
                </el-row>



                <br/>
                <div class="text-center">
                    <el-button type="primary" @click="submitForm()">
                    Confirm
                </el-button>
            </div>
            </el-form>
        
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'ApplicationCreateUpdate',
        data() {
            return {
                patient_id: '',
                patient_details: {},
                patients: [],
                is_form: false,
                form: {
                    user_id: '',
                    father_name: '',
                    mother_name: '',
                    dob: '',
                    village: '',
                    ward: '',
                    region_id: '',
                    town_id: '',
                    father_profession: '',
                    prev_welfare_taken: '0',
                    has_vgf_card: '0',
                    natural_disaster_victim: '0',
                    no_of_family_member: '0',
                    no_of_income_member: '0',
                    no_of_study_member: '0',
                    monthly_family_expense: '',
                    monthly_family_income: '',
                    loan_amount: '',
                    your_monthly_expense: '',
                    has_installment_with_interest: '',
                    has_own_land: '0',
                    has_own_house: '0',
                    has_house_rent: '0',
                    total_service_amount: '0',
                    patient_amount: '0',
                    expected_discount_amount: 0,
                    city_id: '',
                    duty_doctor_comment: '',
                    orko_therapy_package_id: '',
                    recommended_by: '',
                    approved_by:''
                },
                prev_welfare_taken: '',
                districts: [],
                thana: [],
                application_details: {},
                packages: []
            }
        },
        created() {
            if(this.$route.params.id) {
                this.getDetails();
            }
            this.getDistrict(); 
            this.getPackageList();
        },
        methods: {
            getPackageList() {
                axios.get('/api/v2/therapy-packages')
                    .then((response) => {
                    this.packages = response.data.data;
                    });
            },
            clickPackage() {
                let selPackage = this.packages.find((item) => item.id == this.form.orko_therapy_package_id);
                this.form.total_service_amount = selPackage.total;
            },
            calculateExpectedDiscount() {
                if(this.form.total_service_amount && this.form.patient_amount) {
                    this.form.expected_discount_amount = this.form.total_service_amount - this.form.patient_amount;
                } else {
                    this.form.expected_discount_amount = 0;
                }
            },
            getDetails(){
                // this.loading = false;
                axios.get(`/api/v2/welfare/applications/${this.$route.params.id}`)
                .then(res => {
                   this.form = res.data.data;
                   if(res.data.data.prev_welfare_taken > 1) {
                    this.prev_welfare_taken = res.data.data.prev_welfare_taken
                    this.form.prev_welfare_taken = 1;
                   }
                   this.form.fullname = res.data.data.patient_name
                   this.form.region_id = res.data.data.city_id
                //    this.form.town_id = parseInt(res.data.data.town_id);
                   this.patient_details = res.data.data;
                   this.application_details = res.data.data;
                   this.getThana();
                //    console.log('hi', this.form); 
                //    this.form = res.data.data;
                //    this.getDetails()
                })
            },
            
            searchGlobalPatient(value) {
                if (value) {
                    axios
                    .get(`/api/v1/patient/subordinate/list?term=${value}`)
                    .then((response) => {
                        this.patients = response.data.data;
                    
                        if(this.patient_id) {
                            this.patientDetails();
                        }
                    });
                }
            },
            patientDetails() {
                
                axios
                    .get(`/api/v1/user/profile/details/${this.patient_id}`)
                    .then((response) => {
                        console.log('oka', response.data.data);
                        this.patient_details = response.data.data;
                        this.form = response.data.data;
                        this.form.user_id = this.patient_id;
                
                    });
            },
            async getDistrict() {
                await axios.get(`/api/v1/locations?location_type=city&parent_id=18`)
                    .then(response => {
                       
                        this.districts = response.data.location;
                    
                    })
            },
            async getThana() {
                
                await axios.get(`/api/v1/locations?location_type=town&parent_id=${this.form.region_id}`)
                    .then(response => {
                        this.thana = response.data.location;
                    })
            },
            submitForm() {
                this.form.city_id = this.form.region_id;
                if(this.prev_welfare_taken) {
                    this.form.prev_welfare_taken = this.prev_welfare_taken;
                }

                let url = '';
                if(this.$route.params.id) {
                    url = `/api/v2/welfare/applications/${this.application_details.id}?_method=put`;
                } else {
                    url = `/api/v2/welfare/applications`
                }
                axios
                .post(url, this.form)
                .then(res => {
                   
                    if (res.data.status_code != 200) {
                        this.$notify({
                        title: 'Failed',
                        message: res.data.message,
                        type: 'error',
                        duration: 2000,
                        });
                    } else {
                            this.$router.push('/welfare-application');
                            this.$notify({
                            title: 'Success',
                            message: "Successfully application submited",
                            type: 'success',
                            duration: 2000,
                        });
                    }
                    
                })
            },

        }
    }
</script>